import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import styles from './style.module.scss'

function NavigatorPanel(props) {
  const history = useHistory()
  const { item, afterClickHeader } = props
  const onClickHeader = useCallback(() => {
    if (afterClickHeader) {
      afterClickHeader(item)
    }
  }, [afterClickHeader, item])

  const handleMenuItemClick = useCallback(
    (to, target) => {
      if (to.indexOf('timeline') > 0) {
        to += '-mobile'
      }
      if (target === '_blank') {
        window.open(to)
      } else {
        history.push(to)
      }
    },
    [history]
  )

  return (
    <div className={styles.PannelContainer}>
      <div
        className={`${styles.Header} ${item.expanded && styles.HeaderExpanded}`}
        onClick={onClickHeader}
      >
        <div className={styles.LeftArea}>
          <div className={styles.VerticalLine}></div>
          <div>{item.name}</div>
        </div>
        {item.children && item.children.length ? (
          <div className={styles.Arrow}>
            {item.expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </div>
        ) : null}
      </div>
      {item.children && item.children.length ? (
        <div
          className={`${styles.Content} ${
            item.expanded && styles.ContentExpanded
          }`}
        >
          {item.children.map((child) => (
            <div
              className={styles.MenuItem}
              key={child.name}
              onClick={() => handleMenuItemClick(child.path, child.target)}
            >
              {child.name}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

NavigatorPanel.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    expanded: PropTypes.bool,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
      })
    ),
  }),
  afterClickHeader: PropTypes.func,
}

NavigatorPanel.defaultProps = {
  item: {},
  afterClickHeader: () => {},
}

export default React.memo(NavigatorPanel)
