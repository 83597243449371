/*
 * @Date: 2020-12-08 15:03:11
 * @LastEditors: ZHT
 * @LastEditTime: 2021-02-08 16:46:18
 */
import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { renderRoutes } from 'react-router-config'
import { useHistory } from 'react-router'

function Layout(props) {
  const { route } = props
  const menuList = [
    {
      name: '联系我们',
      path: '/contact',
      children: [],
    },
  ]

  const scrollToAnchor = useCallback((hash) => {
    if (hash) {
      const dom = document.getElementById(hash)
      if (dom) {
        const height = dom.offsetTop - 44
        window.scrollTo({ top: height, behavior: 'smooth' })
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [])

  const history = useHistory()

  useEffect(() => {
    history.listen((url) => {
      let { hash } = url
      if (hash) {
        hash = hash.slice(1)
      }
      setTimeout(() => {
        scrollToAnchor(hash)
      })
    })
    const hash = document.location.href.split('#')[1]
    scrollToAnchor(hash)
  }, [history, scrollToAnchor])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Header showExpand menuList={menuList} showHome />
      <div style={{ flex: 1 }}>{renderRoutes(route.routes)}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  route: PropTypes.shape({ routes: PropTypes.arrayOf(PropTypes.object) })
    .isRequired,
}

export default React.memo(Layout)
