import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import routes from './routes/index'
import '../node_modules/antd/dist/antd.css'
import './App.css'

function App() {
  return <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
}

export default App
