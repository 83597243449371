/*
 * @Date: 2020-12-08 15:03:11
 * @LastEditors: ZHT
 * @LastEditTime: 2021-02-08 19:18:17
 */
import React, { memo } from 'react'
import styles from './style.module.scss'

interface Props {
  title: string
  subTitle: string
  titleColor?: string
}

function PageTitle(props: Props) {
  const { title, subTitle, titleColor } = props

  return (
    <div className={styles.TitleWrap}>
      <div className={styles.Title} style={{ color: titleColor }}>
        {title}
      </div>
      <div className={styles.SubTitle}>{subTitle}</div>
    </div>
  )
}
PageTitle.defaultProps = {
  titleColor: 'black',
}

export default memo(PageTitle)
