/*
 * @Date: 2021-01-05 10:20:04
 * @LastEditors: ZHT
 * @LastEditTime: 2021-02-07 14:40:57
 */
import React from 'react'
import styles from './style.module.scss'

function Footer() {
  // const { hostname } = window.location

  // let beianNumber = '无'
  // if (hostname.indexOf('huanle.com')) {
  //   beianNumber = '沪ICP备13014419号'
  // }
  // if (hostname.indexOf('123u.com')) {
  //   beianNumber = '沪ICP备17003506号'
  // }

  return (
    <>
      <div className={styles.PCFooterContainer}>
        <div className={styles.Split}>
          <div className={styles.LogoWrap}>
            <img
              src="https://code-wave-1304070694.cos.ap-shanghai.myqcloud.com/official-site/images/logo_72_52_colorful.png"
              alt="logo"
            />
          </div>
          <div className={styles.Right}>
            {/* <ul className={styles.Nav}>
              <li>
                <a href="https://passport.huanle.com/html/useragreement.html">
                  隐私政策
                </a>
              </li>
              |
              <li>
                <a href="company">关于我们</a>
              </li>
              |
              <li>
                <a href="contact">联系我们</a>
              </li>
              |
              <li> 
                <a href="/hire/socialRecruit" rel="noreferrer">
                  加入我们
                </a>
              </li>
            </ul> */}
            <div className={styles.RightItem}>
              <span>备案号：</span>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://beian.miit.gov.cn"
              >
                鄂ICP备19005630号-1
              </a>
            </div>
            <div className={styles.RightItem}>
              Copyright © 2013- 2020 All Rights Reserved
              麻城叩微网络科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className={styles.MobileFooterContainer}>
        <div className={styles.Item}>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502004658"
          >
            沪公网安备 31011502004658号-1
          </a>
        </div>
        <div className={styles.Item}>增值电信业务许可证：沪B2-20130099</div>
        <div className={styles.Item}>
          <a target="_blank" rel="noreferrer" href="http://beian.miit.gov.cn">
            鄂ICP备19005630号-1
          </a>
        </div>
      </div>
    </>
  )
}

export default React.memo(Footer)
