/*
 * @Date: 2021-02-08 14:56:02
 * @LastEditors: ZHT
 * @LastEditTime: 2021-02-08 18:55:18
 */
import React, { FC, memo } from 'react'

const AdoptPet: FC = () => {
  return (
    <>
      <div></div>
    </>
  )
}
export default memo(AdoptPet)
