import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { NavLink, useHistory } from 'react-router-dom'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import styles from './style.module.scss'

function Header(props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isMobileMenuShow, setIsMobileMenuShow] = useState(false)
  const [offsetZero, setOffsetZero] = useState(true)
  const history = useHistory()

  const { showHome, showExpand, defaultLogo } = props

  let { menuList } = props
  if (showHome) {
    menuList = [
      ...[
        {
          name: '首页',
          path: '/home',
        },
      ],
      ...menuList,
    ]
  }

  const handleMouseOver = useCallback(() => {
    setIsExpanded(true)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setIsExpanded(false)
  }, [])

  const handleMenuItemClick = useCallback(
    (to, target) => {
      if (target === '_blank') {
        window.open(to)
      } else {
        history.push(to)
      }
    },
    [history]
  )

  const onScroll = useCallback((e) => {
    const scrollTop =
      (e.srcElement ? e.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (e.srcElement ? e.srcElement.body.scrollTop : 0)
    setOffsetZero(scrollTop === 0)
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
  }, [onScroll])

  return (
    <div
      className={`${styles.HeaderContainer} ${
        (!offsetZero ||
          defaultLogo === 'colorful' ||
          isMobileMenuShow ||
          (showExpand && isExpanded)) &&
        styles.HeaderContainerWhite
      }`}
    >
      <div className={styles.LogoMobile}>
        <img src="https://code-wave-1304070694.cos.ap-shanghai.myqcloud.com/official-site/images/logo_72_52_colorful.png" />
      </div>
      <div
        className={styles.MenuBtn}
        onClick={() => setIsMobileMenuShow(!isMobileMenuShow)}
      >
        {isMobileMenuShow ? <CloseOutlined /> : <MenuOutlined />}
      </div>
      <div className={styles.HeaderTop}>
        <div className={styles.Logo}>
          {!offsetZero ||
          defaultLogo === 'colorful' ||
          (showExpand && isExpanded) ? (
            <img src="https://code-wave-1304070694.cos.ap-shanghai.myqcloud.com/official-site/images/logo_72_52_colorful.png" />
          ) : (
            <img src="https://code-wave-1304070694.cos.ap-shanghai.myqcloud.com/official-site/images/logo_72_52_white.png" />
          )}
        </div>
        <div className={styles.Menu}>
          {menuList.map((item) => (
            <NavLink
              className={styles.MenuTitle}
              key={item.name}
              to={item.path}
              target={item.target}
              activeClassName={`${showExpand && isExpanded && styles.Active}`}
              onFocus={() => 0}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div
        className={`${styles.ExpandView}  ${
          showExpand && isExpanded && styles.ExpandViewExpanded
        }`}
      >
        <div
          className={styles.MenuColumns}
          onFocus={() => 0}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <div className={styles.Logo}></div>
          {menuList.map((item) => (
            <div className={styles.MenuColumn} key={item.name}>
              {item.children &&
                item.children.length > 0 &&
                item.children.map((child) => (
                  <div
                    className={styles.MenuItem}
                    key={child.name}
                    onClick={() =>
                      handleMenuItemClick(child.path, child.target)
                    }
                  >
                    {child.name}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${styles.MobileMenu} ${
          isMobileMenuShow && styles.MobileMenuShow
        }`}
      >
        {menuList.map((item) => (
          <NavLink
            onClick={() => setIsMobileMenuShow(false)}
            className={styles.MobileMenuTitle}
            key={item.name}
            to={item.path}
            activeClassName={`${isMobileMenuShow && styles.MobileActiveTitle}`}
            target={item.target}
          >
            {item.name}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

Header.propTypes = {
  showExpand: PropTypes.bool,
  showHome: PropTypes.bool,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
          target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
        })
      ),
    })
  ),
  defaultLogo: PropTypes.oneOf(['white', 'colorful']),
}

Header.defaultProps = {
  showExpand: false,
  showHome: false,
  menuList: [],
  defaultLogo: 'white',
}

export default React.memo(Header)
