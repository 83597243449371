/*
 * @Date: 2021-01-05 16:21:07
 * @LastEditors: ZHT
 * @LastEditTime: 2021-02-08 19:18:48
 */
import React, { memo } from 'react'
import PageTitle from '../../components/PageTitle'
import styles from './style.module.scss'

function Map(): JSX.Element {
  return (
    <div className={styles.Map}>
      <picture>
        <source
          srcSet="https://code-wave-1304070694.cos.ap-shanghai.myqcloud.com/official-site/images/about.jpeg"
          media="(min-width: 956px)"
        />
        <img src="https://code-wave-1304070694.cos.ap-shanghai.myqcloud.com/official-site/images/about.jpeg" />
      </picture>
      <div className={styles.Title}>
        <PageTitle title="联系我们" subTitle="Contact Us" titleColor="white" />
      </div>
    </div>
  )
}

function Contact(): JSX.Element {
  const infoList = [
    {
      title: '商务合作',
      name: '朱先生',
      email: 'code_wave@163.com',
    },
    {
      title: '人力资源',
      name: '张女士',
      email: 'hr_code_wave@163.com',
    },
  ]

  return (
    <>
      <Map></Map>
      <div className={styles.ContactContainer}>
        <div className={styles.PCInfo}>
          <div className={styles.CardWrap}>
            {infoList.map((info) => (
              <div className={styles.Item} key={info.name}>
                <div className={styles.ItemTitle}>
                  {/* <img src="https://tc-cdn.123u.com/official-website/contact/logo.png" /> */}
                  <div className={styles.VerticalLine}></div>
                  {info.title}
                </div>
                <div className={styles.ItemContentWrap}>
                  <div className={styles.ItemContent}>
                    <div>{info.name}</div>
                    <div>邮箱：{info.email}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.MobileInfo}>
          {infoList.map((info) => (
            <div className={styles.Info} key={info.name}>
              <div className={styles.Right}>
                <div>{info.name}</div>
                <div>邮箱：{info.email}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default memo(Contact)
