import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { RightCircleOutlined } from '@ant-design/icons'
import styles from './style.module.scss'

function ProductCard(props) {
  const {
    data: { img, title, subTitle, url, closed, coverImg },
  } = props

  const handleClickItem = useCallback(() => {
    if (url) {
      window.open(url)
    }
  }, [url])

  return (
    <div className={styles.Item} onClick={handleClickItem}>
      <div
        className={`${styles.ImageWrap} ${coverImg ? styles.WithCoverImg : ''}`}
      >
        <img src={img} className={styles.ProductImg} alt="英雄杀小游戏" />
        {closed && <div className={styles.ImageCover}>敬请期待</div>}
        {!!coverImg && <img className={styles.QRCode} src={coverImg} />}
      </div>
      <div className={styles.Footer}>
        <div className={styles.Left}>
          <div className={styles.Title}>{title}</div>
          <div className={styles.Abstract}>{subTitle}</div>
        </div>
        <div className={styles.Right}>
          <RightCircleOutlined className={styles.Icon} />
        </div>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  data: PropTypes.shape({
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    url: PropTypes.string,
    closed: PropTypes.bool,
    coverImg: PropTypes.string,
  }).isRequired,
}

export default memo(ProductCard)
