import React, { useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import NavigatorPannel from './NavigatorPanel'

import styles from './style.module.scss'

function BottomNavigator(props) {
  const history = useHistory()
  const { menuList, accordion } = props
  const [menuListInner, setMenuListInner] = useState(
    menuList.map((item) => {
      const tmp = JSON.parse(JSON.stringify(item))
      tmp.expanded = false
      return tmp
    })
  )
  const afterClickHeader = (item) => {
    setMenuListInner(
      menuListInner.map((menu) => {
        if (menu.name === item.name) {
          menu.expanded = !menu.expanded
          if (!item.children || !item.children.length) {
            if (item.target === '_blank') {
              window.open(item.path)
            } else {
              history.push(item.path)
            }
          }
        }
        if (accordion && menu.name !== item.name) {
          menu.expanded = false
        }
        return menu
      })
    )
  }
  return (
    <div className={styles.BottomNavigatorContainer}>
      {menuListInner.map((item) => (
        <NavigatorPannel
          key={item.name}
          item={item}
          afterClickHeader={afterClickHeader}
        />
      ))}
    </div>
  )
}

BottomNavigator.propTypes = {
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
          target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
        })
      ),
    })
  ),
  accordion: PropTypes.bool,
}

BottomNavigator.defaultProps = {
  menuList: [],
  accordion: false,
}

export default React.memo(BottomNavigator)
