/*
 * @Date: 2020-10-10 17:42:17
 * @LastEditors: ZHT
 * @LastEditTime: 2021-02-07 16:23:13
 */
import React from 'react'
import PropTypes from 'prop-types'
import Banner from '../../components/Banner'
import BottomNavigator from './components/BottomNavigator'

import styles from './style.module.scss'

function Home() {
  const menuList = [
    {
      name: '联系我们',
      path: '/contact',
      children: [],
    },
  ]

  return (
    <>
      <div className={styles.Bg}>
        <img
          className={styles.BgImg}
          src="https://code-wave-1304070694.cos.ap-shanghai.myqcloud.com/official-site/images/banner.jpeg"
        />
        <div className={styles.Slogan}>
          <div className={styles.SloganChinese}>让每一行代码都有价值</div>
          <div className={styles.SloganEnglish}>
            make every line of code worthy
          </div>
        </div>
      </div>
      <BottomNavigator menuList={menuList} accordion />
    </>
  )
}

Home.propTypes = {
  route: PropTypes.shape({ routes: PropTypes.arrayOf(PropTypes.object) })
    .isRequired,
}

export default React.memo(Home)
